import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Breadcrumbs,
  ChoicesVue,
  InfoPopup,
  RefreshPopup,
  SidePopup,
  StepVue,
  ToolButton
} from "../components";

import useLocalStorage from "../hooks/useLocalStorage";
import ToRefresh from "../hooks/toRefresh";
import { getDTree, getCurrentID } from "../services/api";
import { getFormattedNode, getRootNode } from "../services/dtree";
import { initHistory, addNodeHistory } from "../services/history";
import { deleteOldLocalStorages } from "../services/local-storage-functions";

import css from "./styles/dtree-page.module.css";

const DTreePage = () => {
  const { id } = useParams();
  const dtreeName = id ? "dtree" + id : "dtree";
  const [dtree, setDTree] = useState(JSON.parse(localStorage.getItem(dtreeName)) || undefined);
  const [appName, setAppName] = useState("Decision Tree Webapp");
  const [popUp, setPopUp] = useState(false);
  const [history, setHistory] = useLocalStorage(dtreeName + "-history", []);
  const [refresh, setRefresh] = ToRefresh(history.length);

  const fetchDTree = useCallback(async () => {
    const results = await getDTree(id);
    initHistory(setHistory, results);
    setDTree(results);
    localStorage.setItem(dtreeName, JSON.stringify(results));
  }, [dtreeName, id, setHistory]);

  useEffect(() => {
    const getID = async (actualId) => {
      const currentID = await getCurrentID();
      if (currentID.id !== actualId) setPopUp("refresh");
    };

    if (id === undefined) {
      const dtreeID = dtree?.id;
      if (dtreeID) getID(dtreeID);
      else fetchDTree();
    } else if (dtree === undefined) {
      deleteOldLocalStorages(id);
      fetchDTree();
    }

    if (dtree) setAppName(getRootNode(dtree).title);
    setRefresh(false);
  }, [fetchDTree, dtree, appName, id, refresh, setRefresh]);

  const closePopup = (e) => {
    if (popUp === "refresh") fetchDTree();
    setPopUp(false);
  };

  if (!dtree || history.length === 0) return <div />;

  const currentNodeId = history[history.length - 1].nodeId;
  const setNextNode = (nextNodeId, nodeId, questionId) => {
    addNodeHistory(history, setHistory, nextNodeId, nodeId, questionId);
  };

  const node = getFormattedNode(dtree, currentNodeId);
  const isStep = node.type === "step";
  return (
    <div className={css.page}>
      <header>
        <h1 className={css.appName}>Salmo-check</h1>
        <div className={css.tools}>
          <ToolButton
            src={"/icon/book.svg"}
            text={"Bibliothèque"}
            onClick={() => setPopUp("library")}
          />
          <ToolButton
            src={"/icon/clipboard.svg"}
            text={"Synthèse"}
            onClick={() => setPopUp("synthesis")}
          />
        </div>
      </header>
      <div className={css.body}>
        {isStep && <img className={css.figureTop} src={"/icon/figure-top.svg"} alt="" />}
        <div>
          <Breadcrumbs dtree={dtree} history={history} setHistory={setHistory} />
          {isStep ? (
            <StepVue
              {...node}
              setNextNode={setNextNode}
              dtree={dtree}
              id={id}
              history={history}
              setHistory={setHistory}
            />
          ) : (
            <ChoicesVue
              {...node}
              setNextNode={setNextNode}
              dtree={dtree}
              history={history}
              setPopUp={setPopUp}
            />
          )}
        </div>
      </div>
      {popUp && (
        <div>
          <div className={css.opacityFilter} onClick={closePopup} />
          {popUp === "info" ? (
            <InfoPopup {...node} dtreeId={dtree.id} setPopUp={setPopUp} />
          ) : popUp === "refresh" ? (
            <RefreshPopup dtree={dtree} history={history} closePopup={closePopup} />
          ) : (
            <SidePopup dtree={dtree} history={history} type={popUp} setPopUp={setPopUp} />
          )}
        </div>
      )}
      <footer>
        <img className={css.logo} src={"/logo/lca.png"} alt="LCA" />
        <img className={css.logo} src={"/logo/snia.png"} alt="SNIA" />
        {isStep && <img className={css.figureBottom} src={"/icon/figure-bottom.svg"} alt="" />}
      </footer>
    </div>
  );
};

export default DTreePage;
