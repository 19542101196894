import { get, buildQueryString } from "./fetcher";
import { withConfiguration } from "./config";

const api = "api";

export const getDTree = async (id) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config[api]}/dtree/${id ? id : ""}`;
    return get(endpoint, {});
  });
};

export const getCurrentID = async () => {
  return withConfiguration(async (config) => {
    const endpoint = `${config[api]}/dtree/id`;
    return get(endpoint, {});
  });
};

/**
 * ? As we juste download the file with href no asnyc
 * ? and no fetcher call (the client browser do the job).
 */
export const getResourceURL = (id, params) => {
  return withConfiguration((config) => {
    const endpoint = `${config[api]}/dtree/${id}/resource`;
    return `${endpoint}?${buildQueryString(params)}`;
  });
};
