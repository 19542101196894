import React from "react";
import { Redirect } from "react-router-dom";

import { clearLocalStorages } from "../services/local-storage-functions";

const ClearPage = () => {
  clearLocalStorages();
  return <Redirect to="/" />;
};

export default ClearPage;
