import React from "react";

import { CornerSquare } from "./index";

import css from "./styles/info-page.module.css";

const InfoPage = ({ title, subhead, description, button, image }) => {
  return (
    <CornerSquare className={css.infoPage}>
      <div className={css.page}>
        <div>
          <div className={css.pageInfo}>
            <div>
              <h1>{title}</h1>
              <div className={css.headline}>{subhead}</div>
            </div>
            <div className={css.line} />
            <div className={css.text}>
              {description.map((text, index) => (
                <p key={index}>{text}</p>
              ))}
            </div>
            {button}
            <div className={css.logos}>
              <img src={"/logo/lca.png"} alt="LCA" />
              <img src={"/logo/snia.png"} alt="SNIA" />
            </div>
          </div>
        </div>
        <img className={css.image} src={image} alt="" />
      </div>
    </CornerSquare>
  );
};

export default InfoPage;
