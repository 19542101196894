import React from "react";
import classnames from "classnames";

import css from "./styles/choice-button.module.css";

const ChoiceButton = ({
  id,
  title,
  childrenId,
  description,
  questionId,
  selected,
  setSelected,
  setNextNode
}) => {
  const choice = classnames(
    css.choice,
    { [css.disabledLink]: !!selected },
    { [css.selected]: id === selected },
    { [css.disabled]: id !== selected && !!selected }
  );
  const onClick = description
    ? () => setSelected(id)
    : () => setNextNode(childrenId[0], id, questionId);

  return (
    <div className={choice} onClick={onClick}>
      <div className={css.scroll}>
        <img className={css.icon} src={"/icon/arrow-right.svg"} alt="" />
        <span className={css.text}>{title}</span>
      </div>
    </div>
  );
};

export default ChoiceButton;
