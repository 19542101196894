import React from "react";

import { ResourcesList, StyledText } from "./index";

import css from "./styles/info-popup.module.css";

const InfoPopup = ({ description, attachements, dtreeId, setPopUp }) => {
  return (
    <div className={css.infoPopup}>
      <div className={css.close} onClick={() => setPopUp(false)}>
        <img src={"/icon/cross.svg"} alt="" />
        <span>Fermer</span>
      </div>
      {description && (
        <div>
          <h2>Plus d'informations</h2>
          <StyledText styledText={description} />
        </div>
      )}
      <ResourcesList attachements={attachements} dtreeId={dtreeId} popup={true} />
    </div>
  );
};

export default InfoPopup;
