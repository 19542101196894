import { getRootNode, getNodeById } from "./dtree";
import { splitStepsTitle, formateDateDMY } from "./string-functions";

export const initHistory = (setHistory, dtree) => {
  const startNode = getRootNode(dtree)?.childrenId[0];
  setHistory([
    { nodeId: getRootNode(dtree)?.id, date: new Date() },
    { nodeId: startNode, date: new Date() }
  ]);
};

export const addNodeHistory = (history, setHistory, nextNodeId, nodeId, questionId) => {
  const nextNodes = nodeId
    ? questionId
      ? [questionId, nodeId, nextNodeId]
      : [nodeId, nextNodeId]
    : [nextNodeId];
  const historyToAdd = nextNodes.map((nodeId) => {
    return { nodeId, date: new Date() };
  });
  setHistory([...history, ...historyToAdd]);
};

export const getPrevTitle = (dtree, history) => {
  const hsize = history.length;
  if (hsize <= 1) return undefined;

  const lastNode = getNodeById(dtree, history[hsize - 2].nodeId);
  if (lastNode.type === "step") return [lastNode.title];
  else if (lastNode.type === "answer" && hsize > 2) {
    const questionNode = getNodeById(dtree, history[hsize - 3].nodeId);
    return [questionNode.title, lastNode.title];
  }
};

export const getHistorySteps = (dtree, history) => {
  return history.filter(({ nodeId }) => {
    const node = getNodeById(dtree, nodeId);
    return node?.type === "step" || node?.type === "app-name";
  });
};

export const getNodesFromStep = (dtree, history, step) => {
  const stepIdx = history.indexOf(step);
  const nextNodes = history.slice(stepIdx + 1, history.length);
  const nextSteps = getHistorySteps(dtree, nextNodes);
  const nextStepIdx = nextSteps.length !== 0 ? nextNodes.indexOf(nextSteps[0]) : nextNodes.length;
  return nextNodes.slice(0, nextStepIdx).map(({ nodeId }) => getNodeById(dtree, nodeId));
};

export const getHistoryCSV = (dtree, history) => {
  const historyStep = getHistorySteps(dtree, history);
  const headers = ["Etapes", "Questions", "Réponses", "Notes"];
  return [
    headers.join("\t"),
    ...historyStep.map((step, idx) => {
      const node = getNodeById(dtree, step.nodeId);
      const notes = step.notes
        ? step.notes.map(({ text, date }) => `${formateDateDMY(date)}: ${text}`).join("\n")
        : "";
      console.log(notes);
      const stepTitle = idx === 0 ? "Etape 0" : splitStepsTitle(node.title).join("- ");
      const midNodes = getNodesFromStep(dtree, history, step);
      const result = midNodes
        .reduce((acc, { title, type }) => {
          if (type === "question") return [...acc, [stepTitle, title]];
          else return [...acc.slice(0, -1), [...acc.slice(-1)[0], title]];
        }, [])
        .map((elem) => elem.join("\t") + "\t" + notes)
        .join("\r\n");
      return result.length === 0 ? `${stepTitle}\t-\t-\t${notes}` : result;
    })
  ].join("\r\n");
};
