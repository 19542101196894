import React from "react";
import DOMPurify from "dompurify";

import css from "./styles/styled-text.module.css";

const StyledText = ({ styledText }) => {
  if (!styledText) return <div className={css.section} />;
  return (
    <div
      className={css.section}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(styledText) }}
    />
  );
};

export default StyledText;
