import React from "react";

import { Button, InfoPage } from "../components";

import css from "./styles/home-page.module.css";

const homePageInfo = {
  title: "Salmo-check",
  subhead: "Pour la gestion des signaux salmonelles par les fabricants d’aliments",
  description: [
    "En tant que fabricant d’aliments composés pour animaux d’élevage, vous êtes amené à gérer des détections de salmonelles, qui surviennent dans votre usine, dans l’usine d’un autre fabricant, au niveau portuaire... Les cas de figure sont multiples.",
    "L'Outil d'Aide à la Décision \"Salmo-check\" vous guide étape par étape, en situation « d’alerte salmonelle » que la salmonelle soit détectée dans votre usine où à un autre niveau de la chaîne, mais aussi au-delà de ces situations d’alerte :",
    "L’outil vous donne des points de repère dans les actions de gestion à mettre en place en usine, suite à la détection d’une salmonelle réglementée ou non, sur une matière première, sur un produit fini ou dans votre environnement de production.",
    "Cet outil pratique vient compléter les documents des Pouvoirs publics et les guides des bonnes pratiques de la profession.",
    "L’entreprise reste bien sûr seule décisionnaire des actions concrètes à mettre en place, en fonction de la situation à laquelle elle est confrontée et de sa démarche HACCP."
  ]
};

const HomePage = () => {
  const button = <Button onClick={() => {}} text={"Entrer"} linkTo={"/dtree"} />;
  const image = "/images/homepage.png";

  return (
    <div className={css.page}>
      <InfoPage {...homePageInfo} button={button} image={image} />
    </div>
  );
};

export default HomePage;
