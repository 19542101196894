import React from "react";
import { useParams } from "react-router-dom";
import classnames from "classnames";

import { Button, InfoPage } from "../components";
import { getHistoryCSV } from "../services/history";
import { deleteLocalStorages } from "../services/local-storage-functions";
import { exportCsvToExcel } from "../services/xlsx-handler";

import css from "./styles/end-page.module.css";

const endPageInfo = {
  title: "Vous êtes arrivé au bout du processus Salmo-check.",
  description: [
    "Vous disposez à présent de votre feuille de route spécifique au signal  que vous venez de prendre en charge. Il vous est possible de télécharger cette feuille de route, qui reprend le cheminement de votre réflexion, les options que vous avez choisies et constitue une trace des actions à mener au sein de votre usine.",
    "Si vous êtes confronté à plusieurs ​détections ou si votre situation ​vous amène à devoir parcourir plusieurs chemins, n’hésitez pas à redémarrer une nouvelle session et à naviguer dans l’application !",
    "Cet outil vise à vous guider et à vous donner des points de repère. L’entreprise reste bien sûr seule décisionnaire des actions concrètes à mettre en place, en fonction de la situation à laquelle elle est confrontée et de sa démarche HACCP."
  ]
};

const EndPage = () => {
  const { id } = useParams();
  const dtreeName = id ? "dtree" + id : "dtree";
  const dtree = JSON.parse(localStorage.getItem(dtreeName));
  const history = JSON.parse(localStorage.getItem(dtreeName + "-history"));

  const clearDTree = (e) => deleteLocalStorages(id);
  const downloadResult = (e) => exportCsvToExcel(getHistoryCSV(dtree, history));

  const restartButton = classnames(css.button, css.restartButton);
  const button = (
    <div className={css.buttons}>
      <Button
        onClick={clearDTree}
        text={"Redémarrer une session"}
        className={restartButton}
        linkTo={"/dtree" + (id ? "/" + id : "")}
      />
      <Button onClick={downloadResult} text={"Imprimer mon récapitulatif"} className={css.button} />
    </div>
  );
  const image = "/images/endpage.png";

  return (
    <div className={css.page}>
      <InfoPage {...endPageInfo} image={image} button={button} />
    </div>
  );
};

export default EndPage;
