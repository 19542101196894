export const getNodeById = (dtree, nodeId) => {
  return dtree?.nodes?.find(({ id }) => id === nodeId);
};

export const getRootNode = (dtree) => {
  const rootNodeId = dtree.rootNodeId;
  return getNodeById(dtree, rootNodeId);
};

export const getFormattedNode = (dtree, nodeId) => {
  const { childrenId, attachementsId, ...node } = getNodeById(dtree, nodeId);

  if (node.type === "step" && node.description === undefined) {
    const { id, title, type, children, attachements } = getFormattedNode(dtree, childrenId[0]);
    return {
      id: node.id,
      title: node.title,
      question: title,
      questionId: id,
      children,
      type,
      attachements
    };
  }

  const children = childrenId.map((id) => {
    return getNodeById(dtree, id);
  });
  const attachements = attachementsId?.map((id) => {
    return getNodeById(dtree, id);
  });
  return { ...node, children, attachements };
};

export const getAttachements = (dtree) => {
  const nodes = dtree.nodes.filter((node) => node.type === "attachement").map((node) => node.title);
  const sortedNodes = nodes.sort((a, b) => a.localeCompare(b));
  return sortedNodes.filter((node, pos, array) => !pos || node !== array[pos - 1]);
};
