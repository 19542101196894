import React, { useState, useRef } from "react";
import classnames from "classnames";

import useElementSize from "../hooks/useElementSize";
import { formateDateDMY } from "../services/string-functions";

import css from "./styles/step-notes.module.css";

const NoteTextInput = ({ addNote, defaultNote = "", toggleAddNote }) => {
  const [newNote, setNewNote] = useState(defaultNote);

  const onChangeNote = (e) => setNewNote(e.target.value);
  const rejectNote = (e) => {
    if (defaultNote && defaultNote !== "") addNote(defaultNote);
    setNewNote("");
    toggleAddNote();
  };
  const validateNote = (e) => {
    if (!newNote || newNote === "") {
      toggleAddNote();
      return;
    }
    addNote(newNote);
    setNewNote("");
    toggleAddNote();
  };

  return (
    <div className={css.noteInput}>
      <textarea value={newNote} onChange={onChangeNote} placeholder="Commentaire de l'étape ..." />
      <div className={css.inputInteraction}>
        <div className={css.interactButton} onClick={rejectNote}>
          <img src={"/icon/cross.svg"} alt="" />
          <span>Annuler</span>
        </div>
        <div className={css.interactButton} onClick={validateNote}>
          <img src={"/icon/check.svg"} alt="" />
          <span>Valider</span>
        </div>
      </div>
    </div>
  );
};

const textOverflowLimit = 66;

const PrintNote = ({ text, date, index, editNote, deleteNote }) => {
  const textRef = useRef(null);
  const { height } = useElementSize(textRef);
  const [extendText, setExtendText] = useState(false);
  const formatedDate = formateDateDMY(date);

  const isMaxHeight = height >= textOverflowLimit;
  const textStyle = classnames(css.textStyle, { [css.textNoMaxHeight]: extendText });
  return (
    <div className={css.printNote}>
      <span>
        <span className={css.date}>{formatedDate}</span>
        <span className={css.changeNoteBox}>
          <span className={css.changeNote} onClick={() => editNote(index)}>
            <img src={"/icon/pen.svg"} alt="" />
            <span>Modifier</span>
          </span>
          <span className={css.changeNote} onClick={() => deleteNote(index)}>
            <img src={"/icon/cross.svg"} alt="" />
            <span>Supprimer</span>
          </span>
        </span>
      </span>
      <div className={css.noteText}>
        <div className={textStyle} ref={textRef}>
          {text}
        </div>
        {isMaxHeight && !extendText && (
          <div className={css.displayMore} onClick={() => setExtendText(true)}>
            Lire plus
          </div>
        )}
      </div>
    </div>
  );
};

const StepNotes = ({ notes, setNotes, doSpace = false }) => {
  const [doAddNote, setDoAddNote] = useState(false);
  const [toEditNote, setToEditNote] = useState();

  const addingNote = (text) => setNotes([...notes, { text, date: new Date() }]);
  const deleteNote = (idx) => setNotes([...notes.filter((e, i) => i !== idx)]);

  const editNote = (idx) => {
    if (!doAddNote) {
      setToEditNote(notes[idx]?.text);
      deleteNote(idx);
      setDoAddNote(true);
    }
  };
  const toggleAddNote = (e) => {
    setDoAddNote(!doAddNote);
    setToEditNote(undefined);
  };

  const stepNotes = classnames(css.stepNotes, { [css.space]: doSpace });

  return (
    <div className={stepNotes}>
      <div>
        <div className={css.title}>
          <img src={"/icon/notes.svg"} alt="" />
          <span>Notes</span>
        </div>
        {notes?.length !== 0 &&
          notes.map((note, index) => (
            <div className={css.noteElem} key={index}>
              <PrintNote {...note} index={index} editNote={editNote} deleteNote={deleteNote} />
              <div className={css.line} />
            </div>
          ))}
        {!doAddNote ? (
          <div className={css.addNote} onClick={toggleAddNote}>
            <img src={"/icon/plus.svg"} alt="" />
            <span>Ajouter une note</span>
          </div>
        ) : (
          <NoteTextInput
            addNote={addingNote}
            defaultNote={toEditNote}
            toggleAddNote={toggleAddNote}
          />
        )}
      </div>
    </div>
  );
};

export default StepNotes;
