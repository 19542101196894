import React from "react";

import css from "./styles/corner-square.module.css";

const CornerSquare = ({ children, className }) => {
  return (
    <div className={className}>
      <img className={css.squareTop} src={"/icon/square-top.svg"} alt="" />
      {children}
      <img className={css.squareBottom} src={"/icon/square-bottom.svg"} alt="" />
    </div>
  );
};

export default CornerSquare;
