import React from "react";

import { Button } from "./index";

import { formateDateDMY } from "../services/string-functions";
import { getHistoryCSV } from "../services/history";
import { exportCsvToExcel } from "../services/xlsx-handler";

import css from "./styles/refresh-popup.module.css";

const RefreshPopup = ({ dtree, history, closePopup }) => {
  const updateDate = formateDateDMY(new Date());
  return (
    <div className={css.refreshPopup}>
      {/* <div className={css.close} onClick={closePopup}>
        <img src={"/icon/cross.svg"} alt="" />
        <span>Fermer</span>
      </div> */}
      <h2>Mise à jour du {updateDate}</h2>
      <div>
        <p>
          Une nouvelle version de l’application a été publiée, nous allons vous déconnecter de cette
          session pour que vous puissiez bénéficier des dernières informations règlementaires.
        </p>
        <p>Pensez à télécharger le récapitulatif si vous voulez sauvegarder votre progression.</p>
      </div>
      <div className={css.buttons}>
        <Button
          text={"Télécharger le récapitulatif"}
          onClick={() => exportCsvToExcel(getHistoryCSV(dtree, history))}
        />
        <Button text={"OK"} onClick={closePopup} />
      </div>
    </div>
  );
};

export default RefreshPopup;
