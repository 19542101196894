import React from "react";

import { Button, Library, Synthesis } from "./index";

import { getHistoryCSV } from "../services/history";
import { exportCsvToExcel } from "../services/xlsx-handler";

import css from "./styles/side-popup.module.css";

const SidePopup = ({ dtree, history, type, setPopUp }) => {
  const isLibrary = type === "library";
  const title = isLibrary ? "Bibliothèque" : "Résumé des saisies";

  const downloadSynthesis = (e) => {
    exportCsvToExcel(getHistoryCSV(dtree, history));
  };

  return (
    <div className={css.sidePopup}>
      <div>
        <div className={css.close} onClick={() => setPopUp(false)}>
          <img src={"/icon/cross.svg"} alt="" />
          <span>Fermer</span>
        </div>
        <div className={css.title}>
          <h2>{title}</h2>
          {!isLibrary && (
            <Button text={"Télécharger le récapitulatif"} onClick={downloadSynthesis} />
          )}
        </div>
        {isLibrary ? <Library dtree={dtree} /> : <Synthesis dtree={dtree} history={history} />}
      </div>
    </div>
  );
};

export default SidePopup;
