import React from "react";
import { usePlausibleGoal } from "../hooks/usePlausible";

import css from "./styles/no-match-page.module.css";

const NoMatchPage = () => {
  usePlausibleGoal("404 Error");
  return <div className={css.page}>404</div>;
};

export default NoMatchPage;
