import React from "react";
import { Switch, Route } from "react-router-dom";

import { ClearPage, DTreePage, EndPage, HomePage, NoMatchPage } from "./pages/index";
import css from "./styles/app.module.css";

// TODO: Change update on header name
// TODO: Handle no connection
const App = () => {
  return (
    <div className={css.app}>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/clear" component={ClearPage} />
        <Route exact path="/dtree/:id" component={DTreePage} />
        <Route exact path="/dtree" component={DTreePage} />
        <Route exact path="/end/:id" component={EndPage} />
        <Route exact path="/end" component={EndPage} />
        <Route component={NoMatchPage} />
      </Switch>
    </div>
  );
};

export default App;
