import XLSX from "xlsx";
import FileSaver from "file-saver";

const excelExtension = ".xlsx";
const fileName = "SalmoCheck-synthese";

export const exportCsvToExcel = (csvString) => {
  const arrayOfArrayCsv = csvString.split("\r\n").map((row) => {
    return row.split("\t");
  });
  const wb = XLSX.utils.book_new();
  const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
  XLSX.utils.book_append_sheet(wb, newWs);
  const rawExcel = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([rawExcel], { type: excelExtension });
  FileSaver.saveAs(data, fileName + excelExtension);
};
