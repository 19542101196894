import { useState, useEffect } from "react";

// const TTL = 3600000; // 1 hour
const TTL = 300000; // 5 min

const ToRefresh = (dependency) => {
  const [lastTime, setLastTime] = useState(new Date());
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    if (currentDate.getTime() - lastTime.getTime() > TTL) {
      setLastTime(new Date());
      setRefresh(true);
    }
  }, [dependency, lastTime, setRefresh, setLastTime]);

  return [refresh, setRefresh];
};

export default ToRefresh;
