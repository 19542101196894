import React from "react";

import { getResourceURL } from "../services/api";
import { getAttachements } from "../services/dtree";

import css from "./styles/library.module.css";

const Library = ({ dtree }) => {
  const attachements = getAttachements(dtree);
  return (
    <div className={css.library}>
      {attachements.map((title, idx) => {
        const url = getResourceURL(dtree.id, { filename: title });
        const document = "Document " + (idx + 1);
        const isLast = idx === attachements.length - 1;
        return (
          <div className={css.document} key={idx}>
            <span>{document}</span>
            <a href={url} target="_blank" rel="noreferrer">
              {title}
            </a>
            {!isLast && <div className={css.line} />}
          </div>
        );
      })}
    </div>
  );
};

export default Library;
