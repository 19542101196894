import React from "react";
import classnames from "classnames";

import { getResourceURL } from "../services/api";

import css from "./styles/resources-list.module.css";

const ResourcesList = ({ attachements, dtreeId, popup = false }) => {
  if (!attachements || attachements.length < 1) return <div />;

  const resourcesList = classnames(css.resourcesList, { [css.inPopup]: popup });
  const title = popup ? "Documents pour vous aider" : "Documents";
  return (
    <div className={resourcesList}>
      <div>
        <div className={css.title}>
          <img src={"/icon/folder.svg"} alt="" />
          <span>{title}</span>
        </div>
        {attachements.map(({ id, title }) => {
          const url = getResourceURL(dtreeId, { filename: title });
          return (
            <div className={css.links} key={id}>
              <img src={"/icon/arrow-right.svg"} alt="" />
              <a href={url} target="_blank" rel="noreferrer">
                {title}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ResourcesList;
