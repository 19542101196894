import React from "react";
import classnames from "classnames";

import { getNodeById } from "../services/dtree";
import { splitStepsTitle, formateDateDMYHM } from "../services/string-functions";
import { getHistorySteps, getNodesFromStep } from "../services/history";

import css from "./styles/synthesis.module.css";

const NodeSynthesis = ({ title, step, date, midNodes, isLast = false }) => {
  const progressLine = classnames(css.progressLine, { [css.isLastLine]: isLast });
  return (
    <div className={css.nodeSynthesis}>
      <div className={css.info}>
        <span>{step}</span>
        <span className={css.date}>{date}</span>
      </div>
      <div className={css.title}>{title}</div>
      {midNodes.length !== 0 && (
        <div className={css.midNodes}>
          {midNodes.map(({ title, type }, idx) => {
            const midNodeStyle = classnames({
              [css.question]: type === "question",
              [css.answer]: type === "answer"
            });
            return (
              <div key={idx} className={midNodeStyle}>
                {title}
              </div>
            );
          })}
        </div>
      )}
      {/* TODO: Check style if last node possible */}
      <div className={progressLine} />
      <div className={css.circle}>
        <img src={"/icon/check.svg"} alt="" />
      </div>
      {!isLast && <div className={css.seperateLine} />}
    </div>
  );
};

const Synthesis = ({ dtree, history }) => {
  const breadcrumbsHistory = getHistorySteps(dtree, history);
  const length = breadcrumbsHistory.length;
  return (
    <div className={css.synthesis}>
      {breadcrumbsHistory.map((step, idx) => {
        const node = getNodeById(dtree, step.nodeId);
        const title = idx === 0 ? ["Etape 0", ""] : splitStepsTitle(node.title);
        const midNodes = getNodesFromStep(dtree, history, step);
        return (
          <NodeSynthesis
            key={step.nodeId}
            title={title[1]}
            step={title[0]}
            date={formateDateDMYHM(step.date)}
            midNodes={midNodes}
            isLast={idx === length - 1}
          />
        );
      })}
    </div>
  );
};

export default Synthesis;
