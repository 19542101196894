import React from "react";
import classnames from "classnames";

import { Button, CornerSquare, PrevTitle, ResourcesList, StepNotes, StyledText } from "./index";
import { splitStepsTitle } from "../services/string-functions";

import css from "./styles/step-vue.module.css";

const StepVue = ({
  title,
  description,
  attachements,
  children,
  setNextNode,
  dtree,
  id,
  history,
  setHistory
}) => {
  const [step, name] = splitStepsTitle(title);
  const hasChildren = children.length !== 0;
  const linkTo = id ? `/end/${id}` : `/end`;
  const resourcesBox = classnames(css.stepVueInfo, css.resourcesBox);

  const lastHistory = history[history.length - 1];
  const setNotes = (newNotes) =>
    setHistory([...history.slice(0, -1), { ...lastHistory, notes: newNotes }]);

  console.log(history);
  return (
    <div className={css.page}>
      <div className={css.stepVue}>
        <PrevTitle dtree={dtree} history={history} />
        <div className={css.stepVueInfo}>
          <div className={css.titles}>
            <div className={css.step}>{step + " :"}</div>
            <h2>{name}</h2>
          </div>
          <div className={css.description}>
            <StyledText styledText={description} />
          </div>
          <div>
            {hasChildren ? (
              <Button onClick={() => setNextNode(children[0].id)} text={"Suite"} />
            ) : (
              <Button onClick={() => {}} text={"Cloturer l'événement"} linkTo={linkTo} />
            )}
          </div>
        </div>
        <div className={resourcesBox}>
          <CornerSquare>
            <ResourcesList attachements={attachements} dtreeId={dtree.id} />
            <StepNotes
              notes={lastHistory?.notes || []}
              setNotes={setNotes}
              doSpace={!!attachements}
            />
          </CornerSquare>
        </div>
      </div>
    </div>
  );
};

export default StepVue;
