export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const splitStepsTitle = (str) => {
  // const results = str.toLowerCase().split(/^(etape \d+:)/);
  // TODO: check on api : in names
  const results = str.split(": ");
  return [capitalize(results[0]), capitalize(results[1])];
};

export const getFirstWords = (str) => {
  const words = str.split(" ");
  if (words.length < 2) return str;
  if (words[0].length + words[1].length < 7) return words.slice(0, 3).join(" ");
  else return words.slice(0, 2).join(" ");
};

export const formateDateDMY = (date) => {
  const today = new Date(date);
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  return dd + "/" + mm + "/" + yyyy;
};

export const formateDateDMYHM = (date) => {
  const today = new Date(date);
  const DMY = formateDateDMY(date);
  const hh = String(today.getHours()).padStart(2, "0");
  const min = String(today.getMinutes()).padStart(2, "0");
  return DMY + " " + hh + ":" + min;
};
