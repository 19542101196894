import React, { useState, useEffect } from "react";

import { Button, ChoiceButton, PrevTitle, StyledText } from "./index";

import css from "./styles/choices-vue.module.css";

const ChoicesVue = ({
  title,
  question,
  questionId,
  description,
  children,
  attachements,
  setNextNode,
  dtree,
  history,
  setPopUp
}) => {
  const [selected, setSelected] = useState();

  const selectedChildren = children.find(({ id }) => id === selected);

  useEffect(() => {
    if (!selectedChildren) setSelected(undefined);
  }, [selectedChildren]);

  return (
    <div className={css.choicesVue}>
      <div className={css.titles}>
        <PrevTitle dtree={dtree} history={history} />
        <div className={css.title}>
          <h2>{title}</h2>
          {(attachements || description) && (
            <img src={"/icon/info.svg"} alt="" onClick={() => setPopUp("info")} />
          )}
        </div>
        {question && <h3>{question}</h3>}
      </div>
      <div className={css.choices}>
        {children.map((child) => {
          return (
            <ChoiceButton
              key={child.id}
              {...child}
              questionId={questionId}
              selected={selected}
              setSelected={setSelected}
              setNextNode={setNextNode}
            />
          );
        })}
      </div>
      {!selectedChildren && (
        <div className={css.description}>Veuillez sélectionner une des réponses ci-dessus</div>
      )}
      {selectedChildren && (
        <div className={css.nextButton}>
          <div className={css.nextDescription}>
            <StyledText styledText={selectedChildren.description} />
          </div>
          <Button
            onClick={() =>
              setNextNode(selectedChildren.childrenId[0], selectedChildren.id, questionId)
            }
            text={"Suite"}
          />
        </div>
      )}
    </div>
  );
};

export default ChoicesVue;
