export const deleteLocalStorages = (idToDelete) => {
  const dtreeToDelete = idToDelete ? `dtree${idToDelete}` : "dtree";
  const historyToDelete = idToDelete ? `dtree${idToDelete}-history` : "dtree-history";
  localStorage.removeItem(dtreeToDelete);
  localStorage.removeItem(historyToDelete);
};

export const deleteOldLocalStorages = (idToKeep) => {
  const keys = Object.keys(localStorage);
  const dtreeIds = keys
    .filter((key) => key.includes("history") && key.match(/\d/g))
    .map((key) => key.replace(/[^\d]/g, ""));
  const idToDelete = dtreeIds.sort().slice(0, -3);
  idToDelete.forEach((id) => {
    if (id !== idToKeep) {
      localStorage.removeItem(`dtree${id}`);
      localStorage.removeItem(`dtree${id}-history`);
    }
  });
};

export const clearLocalStorages = () => {
  const keys = Object.keys(localStorage);
  keys.forEach((key) => localStorage.removeItem(key));
};
