import * as React from "react";

const usePlausible = () => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    window.plausible =
      window.plausible ||
      function () {
        (window.plausible.q = window.plausible.q || []).push(arguments);
      };
    setLoaded(true);
  }, []);
  return [loaded, setLoaded];
};

export const usePlausibleGoal = (goal) => {
  const [loaded] = usePlausible();
  React.useEffect(() => {
    if (!loaded || !window.plausible) return;
    window.plausible(goal, { props: { path: document.location.pathname } });
  }, [goal, loaded]);
};
