import React from "react";

import { getPrevTitle } from "../services/history";

import css from "./styles/prev-title.module.css";

const PrevTitle = ({ dtree, history }) => {
  const prevTitles = getPrevTitle(dtree, history);
  if (!prevTitles) return <div />;
  return (
    <div className={css.prevTitles}>
      <div className={css.lastTitle}>{prevTitles[0]}</div>
      {prevTitles.length === 2 && <div className={css.answerTitle}>{prevTitles[1]}</div>}
    </div>
  );
};

export default PrevTitle;
