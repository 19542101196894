import React from "react";

import css from "./styles/tool-button.module.css";

const ToolButton = ({ src, text, onClick }) => {
  return (
    <div onClick={onClick} className={css.tool}>
      <img className={css.icon} src={src} alt="" />
      <span>{text}</span>
    </div>
  );
};

export default ToolButton;
