import React, { useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import classnames from "classnames";

import { getNodeById } from "../services/dtree";
import { splitStepsTitle } from "../services/string-functions";
import { getHistorySteps } from "../services/history";

import css from "./styles/breadcrumbs.module.css";

const NodeLink = ({ title, onClick, isLast = false, isTestDtree = false }) => {
  const link = classnames(css.link, { [css.isLast]: isLast }, { [css.disabled]: isTestDtree });
  return (
    <div className={css.nodeLink}>
      <span className={link} onClick={onClick}>
        <span>{title}</span>
        <div className={css.toolTip}>Vous ne pouvez pas revenir à l'accueil en mode "test OAD"</div>
      </span>
      {!isLast && <span className={css.next}>{">"}</span>}
    </div>
  );
};

const Breadcrumbs = ({ dtree, history, setHistory }) => {
  const [redirect, setRedirect] = useState(false);
  const location = useLocation();
  const isTestDtree = location.pathname.includes("/dtree/") && location.pathname.length > 7;

  const returnToNode = (node) => {
    const idx = history.indexOf(node);
    setHistory(history.slice(0, idx + 1));
  };
  const setToHomepage = (node) => () => {
    // ? Un comment to delete history
    // returnToNode(node);
    if (!isTestDtree) setRedirect(true);
  };

  const breadcrumbsHistory = getHistorySteps(dtree, history);
  const length = breadcrumbsHistory.length;
  if (redirect) return <Redirect to="/" />;
  return (
    <div className={css.breadcrumbs}>
      <NodeLink title={"Accueil"} onClick={setToHomepage(history[1])} isTestDtree={isTestDtree} />
      <NodeLink title={"Etape 0"} onClick={() => returnToNode(history[1])} isLast={length === 1} />
      {breadcrumbsHistory.map((step, idx) => {
        if (idx === 0) return <span key={step.nodeId} />;
        const node = getNodeById(dtree, step.nodeId);
        const title = splitStepsTitle(node.title);
        return (
          <NodeLink
            key={step.nodeId}
            title={title[0]}
            onClick={() => returnToNode(step)}
            isLast={idx === length - 1}
          />
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
