import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

import css from "./styles/button.module.css";

const Button = ({ text, onClick, className, linkTo }) => {
  const buttonCss = classnames(css.button, { [className]: className });
  const button = (
    <div className={buttonCss} onClick={onClick}>
      <span className={css.text}>{text}</span>
    </div>
  );

  return linkTo ? (
    <Link className={css.link} to={linkTo}>
      {button}
    </Link>
  ) : (
    button
  );
};

export default Button;
